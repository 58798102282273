var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    attrs: {
      "displayTabs": _vm.isFilterOpen
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.dashboardLink
          }
        }, [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1), _vm._v(" Nye dokumenter ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.selectedRows.length > 0 ? _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "outlined": "",
            "data-cy": "markAsReadBtn"
          },
          on: {
            "click": function click($event) {
              return _vm.markAsRead(_vm.selectedRows);
            }
          }
        }, [_vm._v(" Marker som sett ")]) : _vm._e(), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.isFilterOpen = !_vm.isFilterOpen;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter-variant")]), _vm._v(" Filter ")], 1)];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function fn() {
        return [!_vm.currentGroupBy ? _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateGroupBy('courseId');
            }
          }
        }, [_vm._v(" Grupper etter ID ")]) : _vm._e(), !_vm.currentGroupBy ? _c('v-btn', {
          attrs: {
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateGroupBy('courseParticipantName');
            }
          }
        }, [_vm._v(" Grupper etter navn ")]) : _vm._e(), _vm.currentGroupBy ? _c('v-btn', {
          attrs: {
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateGroupBy(null);
            }
          }
        }, [_vm._v(" Fjern gruppering ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.flattenedDocuments,
      "group-by": _vm.currentGroupBy,
      "sort-by": ['courseId', 'courseParticipantName'],
      "item-key": "fileId",
      "show-select": ""
    },
    on: {
      "toggle-select-all": _vm.selectAllToggle,
      "update:group-by": _vm.updateGroupBy
    },
    scopedSlots: _vm._u([{
      key: "item.data-table-select",
      fn: function fn(_ref) {
        var item = _ref.item,
            isSelected = _ref.isSelected,
            select = _ref.select;
        return [_c('v-simple-checkbox', {
          attrs: {
            "ripple": false,
            "value": isSelected,
            "readonly": item.isCourseAdminRead,
            "disabled": item.isCourseAdminRead
          },
          on: {
            "input": function input($event) {
              return select($event);
            }
          }
        })];
      }
    }, {
      key: "groupHeader",
      fn: function fn(_ref2) {
        var group = _ref2.group;
        return [_vm._v(" " + _vm._s(_vm.formatGroupBy(group)) + " ")];
      }
    }, {
      key: "item.courseParticipantStatus",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(_vm._s(_vm.capitalize(value)))];
      }
    }, {
      key: "item.inserted",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDateP(value)) + " ")];
      }
    }, {
      key: "item.isCourseAdminRead",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(value ? "Ja" : "Nei") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on,
                  attrs = _ref7.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "color": "primary",
                  "to": _vm.getCourseLink(item.courseId, item.courseParticipantName)
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis kurset")])])];
      }
    }], null, true),
    model: {
      value: _vm.selectedRows,
      callback: function callback($$v) {
        _vm.selectedRows = $$v;
      },
      expression: "selectedRows"
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }